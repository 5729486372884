$color_1: rgba(0, 0, 0, 0.8);
$color_2: #242424;
$color_3: #fff;
$color_4: #7c8859;
$color_5: #222;
$color_6: #c54041;
$color_7: #b99769;
$color_8: #4d8d0d;
$color_9: #bfbfbf;
$color_10: #00d747;
$color_11: #9262ff;
$color_12: #088ed3;
$color_13: #c9c9c9;
$color_14: #808080;
$color_15: #000;
$color_16: #4e595f;
$color_17: #888;
$color_18: #333;
$color_19: #837f7e;
$color_20: #eb1c22;
$font-family_1: "Roboto", sans-serif;
$font-family_2: "FontAwesome";
$background-color_1: $color_3;
$background-color_2: #222;
$background-color_3: #f5f8f9;
$border-color_1: #dedede;
$border-color_2: $color_4;

html {
  overflow-x: hidden;
}
body {
  line-height: 1.5;
  font-family: $font-family_1;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  color: $color_1;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-family_1;
  font-weight: normal;
  color: $color_2;
  letter-spacing: -0.03em;
}
h1,
.h1 {
  font-size: 2.5rem;
}
h2,
.h2 {
  font-size: 2rem;
  line-height: 42px;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1.3rem;
  line-height: 30px;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}
p {
  line-height: 30px;
}
.navbar-toggle {
  .icon-bar {
    background: #7c8859;
  }
}
.py-7 {
  padding: 7rem 0px;
}
.bg-gray,
.bg-grey {
  background: $background-color_3;
}
.bg-primary {
  background: #7c8859;
}
.bg-primary-dark {
  background: #f52626;
}
.bg-primary-darker {
  background: #dd0b0b;
}
.bg-dark {
  background: #242424;
}
.bg-black {
  background: #000;
}
.bg-white {
  background: $color_3;
}
.bg-gradient {
  background-image: linear-gradient(145deg, rgba(19, 177, 205, 0.95) 0%, rgba(152, 119, 234, 0.95) 100%);
  background-repeat: repeat-x;
}
.bg-translucent {
  background: rgba(255, 255, 255, 0.94);
}
.section {
  padding: 60px 0 80px;
}
.section-md {
  padding: 70px 0;
}
.section-sm {
  padding: 60px 0;
}
.section-xsm {
  padding: 30px 0;
}
.section-title {
  margin-bottom: 70px;
  .title {
    font-size: 50px;
    line-height: 50px;
  }
  p {
    color: $color_5;
  }
}
.subtitle {
  color: $color_4;
  font-size: 14px;
  letter-spacing: 1px;
}
.overly {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
  }
}
.hero-img {
  position: relative;
  background: url("../images/banner-xy-sm.jpg") center/cover no-repeat;
  // position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
  }
}
.cta {
  position: relative;
  background: url("../images/bg/bg-cta-1.jpg") fixed 50% 50%;
  background-size: cover;
  padding: 120px 0px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
  }
}
.cta-2 {
  background: url("../images/bg/bg-cta-2.jpg") fixed 50% 50%;
  background-size: cover;
}
.cta-3 {
  background: url("../images/bg/bg-v05.jpg") fixed 50% 50%;
  background-size: cover;
}
.cta-4 {
  background: url("../images/bg/bg-v06.jpg") fixed 50% 50%;
  background-size: cover;
}
.slider {
  position: relative;
  padding: 350px 0 240px;
  position: relative;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
  }
  a {
    &.btn {
      &:hover {
        transform: scale(1.08);
        transition: all 0.4s ease;
      }
    }
  }
  .block {
    h1 {
      font-size: 70px;
      line-height: 80px;
      color: $color_3;
      text-shadow: 1px 1px 24px #444;
    }
    p {
      margin-bottom: 30px;
      color: $color_9;
      font-size: 18px;
      line-height: 27px;
      font-weight: 300;
    }
    span {
      letter-spacing: 1px;
    }
  }
}
.page-title {
  position: relative;
  padding: 100px 0;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
  }
  .block {
    h1 {
      color: $color_3;
    }
    p {
      color: $color_3;
    }
  }
}
.overlay-2 {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
}
.latest-news {
  position: relative;
  padding-bottom: 120px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
}
// .cta-block {
//   position: relative;
//   background: url("../images/bg/home-3.jpg") no-repeat;
//   background-size: cover;
//   &:before {
//     content: "";
//     position: absolute;
//     left: 0;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.8);
//   }
// }
.register {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
}
.bg-v1,
.bg-v2,
.bg-v4 {
  position: relative;
  // background: url("") no-repeat;
  background-size: cover;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
  }
}
.bg-v1 {
  background: url("../images/bg/bg-v03.jpg");
}
.bg-v2 {
  background: url("../images/bg/bg-v02.jpg");
}
.bg-v3 {
  background: url("../images/bg/bg-v03.jpg") fixed bottom center no-repeat;
  padding: 40px 0px;
}
.bg-v4 {
  background: url("../images/bg/bg-v04.jpg") fixed bottom center no-repeat;
  position: relative;
  background-size: cover;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
  }
}
.text-color {
  color: $color_4;
}
.text-black {
  color: $color_2;
}
.text-white {
  color: $color_3;
}
.text-white-65 {
  color: $color_3;
  opacity: 65%;
}
.text-white-70 {
  color: $color_3;
  opacity: 70%;
}
.text-color2 {
  color: $color_6;
  // color: $color_7;
}
.bright-green {
  color: $color_8;
}
.text-sm {
  font-size: 14px;
}
.text-md {
  font-size: 2.25rem;
}
.text-lg {
  font-size: 3.75rem;
}
.no-spacing {
  letter-spacing: 0px;
}
a {
  color: $color_8;
  text-decoration: none;
  &:focus {
    color: $color_4;
    text-decoration: none;
    outline: none;
  }
  &:hover {
    color: $color_4;
    text-decoration: none;
  }
}
.content-title {
  font-size: 40px;
  line-height: 4.5rem;
  &-large {
    font-size: 64px;
  }
}
.content-title.lh-small {
  line-height: 3.2rem !important;
}
.page-wrapper {
  padding: 70px 0;
}
#wrapper-work {
  overflow: hidden;
  padding-top: 100px;
  ul {
    li {
      width: 50%;
      float: left;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .items-text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        color: $color_3;
        background: rgba(0, 0, 0, 0.6);
        padding-left: 44px;
        padding-top: 140px;
        h2 {
          padding-bottom: 28px;
          padding-top: 75px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 75px;
            height: 3px;
            background: #fff;
          }
        }
        p {
          padding-top: 30px;
          font-size: 16px;
          line-height: 27px;
          font-weight: 300;
          padding-right: 80px;
        }
      }
    }
  }
}
// #features-work {
//   padding-top: 50px;
//   padding-bottom: 75px;
//   .block {
//     ul {
//       li {
//         width: 19%;
//         text-align: center;
//         display: inline-block;
//         padding: 40px 0px;
//       }
//     }
//   }
// }
#navbar {
  background: rgba(0, 0, 0, 0.5);
  li {
    padding-left: 13px;
  }
  .nav-link {
    font-weight: 500;
    color: $color_9;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: -0.01em;
    transition: all 0.25s ease;
    &:hover {
      color: $color_3;
    }
    &:focus {
      color: $color_3;
    }
  }
  .active {
    .nav-link {
      color: $color_3;
    }
  }
  .btn {
    padding: 0.7rem 1.5rem 0.5rem;
    color: $color_3;
  }
}
.header-top {
  background: rgba(0, 0, 0, 0.5);
  color: $color_3;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  .header-top-notice {
    padding: 12px 0px;
    margin-left: 8px;
    min-height: 2.8rem;
    a {
      color: $color_3;
      margin-right: 8px;
      font-size: 18px;
      padding: 0 8px;
      &:hover {
        color: $color_4;
      }
    }
  }
  .header-top-socials {
    color: $color_3;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    a {
      span {
        color: $color_3;
      }
      margin-left: 23px;
      color: $color_3;
      &.btn {
        margin-left: 12px;
      }
    }
    .social-header {
      // padding-right: 40px;
      a {
        display: inline;
        vertical-align: middle;
        font-size: 20px;
      }
    }
  }
}
.social-header,
.sidebar-socials,
.footer-socials {
  a:hover i {
    transform: scale(1.25);
    transition: transform 0.2s ease;
  }
}
.navbar-toggler {
  padding: 0;
  font-size: 1.5rem;
  color: $color_3;
  &:focus {
    outline: 0;
  }
}
.navbar-brand {
  color: $color_3;
  font-weight: 600;
  letter-spacing: 1px;
  span {
    color: $color_4;
  }
}
.dropdown-menu {
  padding: 0px;
  border: 0;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
  li {
    &:first-child {
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 5px;
    }
  }
}
.dropdown-toggle {
  &::after {
    display: none;
  }
}
.dropleft {
  .dropdown-menu {
    margin: 0;
  }
  .dropdown-toggle {
    &::before {
      font-weight: bold;
      font-family: $font-family_2;
      border: 0;
      font-size: 10px;
      vertical-align: 1px;
      content: "\f053";
      margin-right: 5px;
    }
  }
}
.dropright {
  .dropdown-menu {
    margin: 0;
  }
  .dropdown-toggle {
    &::after {
      font-weight: bold;
      font-family: $font-family_2;
      border: 0;
      font-size: 10px;
      vertical-align: 1px;
      content: "\f054";
      margin-left: 5px;
    }
  }
}

.dropdown-item {
  padding: 0.8rem 1.5rem 0.55rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: $color_5;
  &:hover {
    background: #7c8859;
    color: $color_3;
  }
}
.dropdown-submenu.active {
  > .dropdown-toggle {
    background: #7c8859;
    color: $color_3;
  }
}
.dropdown-submenu {
  &:hover {
    > .dropdown-item {
      background: #7c8859;
      color: $color_3;
    }
  }
}
.dropdown-item.active {
  background: #7c8859;
  color: $color_3;
}
ul.dropdown-menu {
  li {
    padding-left: 0px !important;
  }
}
.bg-1 {
  background: url("../images/banner-x.jpg") no-repeat 50% 50%;
  background-size: cover;
}
.bg-2 {
  background: url("../images/banner-xy.jpg") no-repeat 50% 50%;
  background-size: cover;
}
.intro-item {
  i {
    font-size: 60px;
    line-height: 60px;
  }
}
.color-one {
  color: $color_4;
}
.color-two {
  color: $color_10;
}
.color-three {
  color: $color_11;
}
.color-four {
  color: $color_12;
}
.about-content {
  padding: 20px 0px 0px 80px;
  h4 {
    font-weight: 600;
    &:before {
      position: absolute;
      content: "\f576";
      font-family: $font-family_2;
      font-size: 30px;
      position: absolute;
      top: 8px;
      left: -65px;
      font-weight: 700;
    }
  }
}
.counter-item {
  .counter-stat {
    font-size: 50px;
  }
  p {
    margin-bottom: 0px;
  }
}
.team-img-hover,
.resource-img-hover {
  .team-social {
    li {
      a.facebook {
        background: #6666cc;
      }
      a.twitter {
        background: #3399cc;
      }
      a.instagram {
        background: #cc66cc;
      }
      a.linkedin {
        background: #3399cc;
      }
    }
  }
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
  li {
    a {
      display: inline-block;
      color: $color_3;
      width: 50px;
      height: 50px;
      font-size: 20px;
      line-height: 50px;
      border: 2px solid transparent;
      border-radius: 2px;
      text-align: center;
      transform: translateY(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
  }
  &:hover {
    li {
      a {
        &:hover {
          transform: translateY(4px);
        }
      }
    }
  }
}
.card-main {
  // border-color: $color_4;
  .card-header .card .card-header,
  .card-header .card-body {
    background: transparent;
  }
  .card {
    border: 0 none;
  }
  // :first-child .card-header {background:transparent ;}
  // .card_header {background-color: $color_12;}
}
.team-item,
.resource-item,
.card-item {
  &:hover {
    .team-img-hover,
    .resource-img-hover,
    .card-item {
      opacity: 1;
      transform: scale(1);
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
  &-content h4 {
    font-size: 1em;
    color: $color_15;
  }
  &-content p {
    font-size: 0.9em;
  }
  &-wrap {
    &:hover {
      border: 1px solid $color_13;
      transition: all ease-in-out 0.2s;
      transform: scale(1.07);
    }
  }
  &-wrap.no-border {
    &:hover {
      border: 0 none !important;
    }
  }
}
.resource-item-wrap {
  &:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out 0.2s;
    transform: scale(1.05);

    .btn {
      padding-inline: 1.4rem !important;
    }
  }
}
.board-member {
  &-wrap {
    background: $color_3;
  }
}
.service-item {
  position: relative;
  padding-left: 75px;
  i {
    position: absolute;
    left: 0px;
    top: 5px;
    font-size: 50px;
    opacity: 0.4;
  }
}
.contact-item {
  position: relative;
  padding-left: 75px;
  i {
    position: absolute;
    left: 0px;
    top: 5px;
    font-size: 50px;
  }
}
.contact-inpage {
  i {
    font-size: 50px;
  }
  p {
    padding-top: 1.3rem;
  }
}
.check-item {
  position: relative;
  padding-left: 75px;
  i {
    position: absolute;
    left: 0px;
    top: 5px;
    font-size: 50px;
  }
}
.icon-smaller {
  padding-left: 50px;
  i {
    font-size: 32px;
  }
  p {
    padding-top: 5px;
  }
}
.testimonial-item {
  padding: 50px 30px;
  i {
    font-size: 40px;
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 1;
  }
  .testimonial-text {
    font-size: 20px;
    line-height: 38px;
    color: $color_2;
    margin-bottom: 30px;
    font-style: italic;
  }
  .testimonial-item-content {
    padding-left: 65px;
  }
}
.testimonials {
  &-entry:nth-child(even) {
    padding-left: 0;
    background: $background-color_3;
  }
  &-content {
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .row {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .fancy-quote {
    margin-top: 40px !important;
    margin-bottom: 30px !important;
  }
  .blockquote-footer {
    font-size: 16px !important;
  }
}
.contributors-wrap {
  img {
    width: 80%;
  }
}
.supporters-wrap {
  img {
    width: 80%;
  }
}
.slick-slide {
  &:focus {
    outline: none;
  }
  a {
    outline: none;
  }
}
.h70 {
  height: 55%;
}
.lh-45 {
  line-height: 45px;
}
.modal-xl {
  max-width: 1200px;
}
// .tab-pane .accordion a.card-link {
.accordion a.card-link {
  color: $color_5;
  font-weight: 500;
}
.accordion .card-header {
  padding-top: 1.3rem;
  padding-bottom: 1rem;
}
.trades .accordion .card-body ul,
.trades .accordion .card-body ol {
  list-style-type: none !important;
}
.portflio-item {
  .portfolio-item-content {
    position: absolute;
    content: "";
    right: 0px;
    bottom: 0px;
    opacity: 0;
    transition: all 0.35s ease;
  }
  &:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: all 0.35s ease;
    overflow: hidden;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    .portfolio-item-content {
      opacity: 1;
      bottom: 20px;
      right: 30px;
    }
    .overlay-item {
      opacity: 1;
    }
  }
  .overlay-item {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    color: $color_4;
    opacity: 0;
    transition: all 0.35s ease;
  }
}
.contact-form-wrap {
  .form-group {
    padding-bottom: 15px;
    margin: 0px;
    .form-control {
      background: $background-color_3;
      height: 48px;
      border: 1px solid #eef2f6;
      box-shadow: none;
      width: 100%;
    }
  }
  .form-group-2 {
    margin-bottom: 13px;
    textarea {
      background: $background-color_3;
      height: 135px;
      border: 1px solid #eef2f6;
      box-shadow: none;
      width: 100%;
    }
  }
}
.address-block {
  li {
    margin-bottom: 10px;
    i {
      font-size: 20px;
      width: 20px;
    }
  }
}
.social-icons {
  li {
    margin: 0 6px;
  }
  i {
    margin-right: 15px;
    font-size: 25px;
  }
}
.google-map {
  position: relative;
  #map {
    width: 100%;
    height: 450px;
  }
}
.news-item-content {
  h2 {
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    line-height: 40px;
    // letter-spacing: -1px;
  }
  h3 {
    line-height: 36px;
    a {
      transition: all 0.4s ease 0s;
      &:hover {
        color: $color_4 !important;
      }
    }
  }
}
.lh-36 {
  line-height: 36px;
}
.tags {
  a {
    background: $background-color_3;
    display: inline-block;
    padding: 8px 23px;
    border-radius: 38px;
    margin-bottom: 10px;
    border: 1px solid #eee;
    font-size: 14px;
    text-transform: capitalize;
  }
}
.pagination {
  .nav-links {
    a {
      font-size: 20px;
      font-weight: 500;
      color: $color_13;
      margin: 0 10px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
    }
    span.current {
      font-size: 20px;
      font-weight: 500;
      color: $color_13;
      margin: 0 10px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      color: $color_2;
    }
    a.next {
      color: $color_2;
    }
    a.prev {
      color: $color_2;
    }
  }
}
#datatable-general_info,
#datatable-general-1_info,
#datatable-general-2_info {
  margin-top: 15px;
}
#datatable-general_paginate,
#datatable-general-1_paginate,
#datatable-general-2_paginate {
  margin-top: 25px;
  a:focus {
    outline-color: transparent;
    box-shadow: 0 0 5px $color_4;
  }
  .paginate_button a {
    color: $color_2;
  }
  .disabled a {
    color: $color_17;
  }
  .active a {
    color: $color_3;
    background: $color_4;
    border-color: $color_4;
  }
}
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  background-color: $color_4;
}
h3.quote,
.fancy-quote {
  font-size: 24px;
  line-height: 40px;
  font-weight: normal;
  padding: 0px 25px 0px 85px;
  margin: 65px 0 65px 0 !important;
  position: relative;
  &::before {
    content: "";
    width: 55px;
    height: 2px;
    background: #7c8859;
    position: absolute;
    top: 25px;
    left: 0;
  }
}
.fancy-quote .blockquote-footer {
  font-size: initial;
  line-height: 1.8rem;
}
.mt-70 {
  margin-top: -70px;
}
.border-1 {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.social-profile {
  ul {
    li {
      margin: 0 10px 0 0;
      display: inline-block;
      a {
        color: $color_16;
        display: block;
        font-size: 16px;
        i {
          &:hover {
            color: $color_4;
          }
        }
      }
    }
  }
}
.widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
  .widget-title {
    margin-bottom: 15px;
    padding-bottom: 10px;
    font-size: 16px;
    color: $color_18;
    font-weight: 500;
    border-bottom: 1px solid #dedede;
  }
}
.widget.widget-latest-post {
  .media {
    .media-object {
      width: 100px;
      height: auto;
    }
    .media-heading {
      a {
        color: $color_2;
        font-size: 16px;
      }
    }
    p {
      font-size: 12px;
      color: $color_14;
    }
  }
}
.widget.widget-category {
  ul {
    li {
      margin-bottom: 10px;
      a {
        color: $color_19;
        transition: all 0.3s ease;
        &:before {
          padding-right: 10px;
        }
        &:hover {
          color: $color_4;
          padding-left: 5px;
        }
      }
    }
  }
}
.widget.widget-tag {
  ul {
    li {
      margin-bottom: 10px;
      display: inline-block;
      margin-right: 5px;
      a {
        color: $color_19;
        display: inline-block;
        padding: 8px 15px;
        border: 1px solid #dedede;
        border-radius: 30px;
        font-size: 14px;
        transition: all 0.3s ease;
        &:hover {
          color: $color_3;
          background: #7c8859;
          border: 1px solid #7c8859;
        }
      }
    }
  }
}
.footer {
  background: #000;
  padding-bottom: 10px;
  .footer-menu {
    li a {
      color: $color_3;
    }
    .navbar-nav > li > a.active,
    .navbar-nav li.active a {
      color: $color_4;
      font-weight: 400;
    }
    li {
      padding: 0 32px;
      li {
        padding: 0;
        a.active {
          color: $color_3;
        }
        a {
          color: $color_5;
          // font-weight: bold;
          padding-block: 6px;
          &:hover {
            color: $color_3;
          }
        }
      }
    }
  }
  .copyright {
    color: $color_3;
    font-size: 15px;
    p {
      line-height: 1.4rem;
    }
    a {
      font-weight: 600;
    }
  }
  .footer-socials {
    font-size: 30px;
  }
}
.footer-menu {
  a {
    &:hover {
      color: $color_20;
    }
  }
}
.footer-btm {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.footer-socials {
  li {
    a {
      color: $color_3;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}
// .sidebar-socials li.first {
.sidebar-socials {
  text-align: left;
  a {
    color: $color_2;
    font-size: 22px;
    margin-left: 10px;
    &:hover {
      color: $color_4;
    }
  }
  li:first-child {
    a {
      margin-left: 5px;
    }
  }
}
.logo {
  color: $color_2;
  font-weight: 600;
  letter-spacing: 1px;
  span {
    color: $color_4;
  }
}
.lh-35 {
  line-height: 35px;
}
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  height: 40px;
  width: 40px;
  background: $color_4;
  border-radius: 50%;
  text-align: center;
  line-height: 43px;
  color: $color_3;
  cursor: pointer;
  transition: 0.3s;
  display: none;
  &:hover {
    background-color: $background-color_2;
    border: 1px solid #fffffe37;
  }
}

//_buttons
.btn {
  display: inline-block;
  font-size: 18px;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.8rem 2.3rem 0.6rem;
  text-transform: uppercase;
  border-radius: 0;
  transition: 0.3s;
  &:focus {
    outline: 0px;
    box-shadow: none;
    outline: 0;
  }
  &:active {
    &:focus {
      outline: 0;
    }
  }
}
.btn.btn-icon {
  i {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.btn-main,
.btn-secondary:not(:disabled),
.btn-bright-green,
.btn-bright-blue,
.btn-small,
.btn-xsmall {
  color: $color_3;
  transition: all 0.5s ease;
  &,
  &:active,
  &:hover {
    border-color: transparent;
  }
  &:hover {
    background: #000;
    color: $color_3;
    // transition: all 0.1s ease;
  }
}
.btn-dark-hover {
  &:hover {
    background: $color_2;
  }
}
.btn-main,
.bg-green {
  background: $color_4;
}
.btn-secondary {
  background: $color_12;
}
.btn-bright-green,
.bg-bright-green {
  background: $color_8;
}
.opportunities a.active,
.tabs-secion a.active {
  color: $color_3;
  &:hover {
    background: $color_12;
  }
}
.btn-bright-blue,
.opportunities a.active,
.tabs-secion a.active,
.bg-bright-blue {
  background: $color_12;
}
.btn-small {
  padding: 13px 25px 10px !important;
  font-size: 16px !important;
}
.btn-smaller {
  padding: 10px 18px 9px !important;
  font-size: 14px !important;
}
.btn-xsmall {
  padding: 5px 12px 4px !important;
  font-size: 12px !important;
}
.btn-transparent {
  background: $color_4;
  color: $color_3;
  transition: all 0.2s ease;
  background: transparent;
  padding: 0;
  color: $color_4;
  &:hover {
    background: #000;
    color: $color_3;
    background: transparent;
    color: $color_4;
  }
}
.btn-main.inverse,
.btn-bright-blue {
  background: #000;
}
.btn-main.inverse:hover {
  background: $color_4;
}
.btn-bright-blue.inverse:hover {
  background: $color_12;
}
.btn-solid-border {
  border: 2px solid #7c8859;
  background: transparent;
  color: $color_2;
  &:hover {
    border: 2px solid #7c8859;
    background: #7c8859;
  }
}
.btn-large {
  padding: 20px 45px;
}
.btn-large.btn-icon {
  i {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.btn-round {
  border-radius: 4px;
}
.btn-round-full {
  border-radius: 50px;
}
.btn.active {
  &:focus {
    outline: 0;
  }
}

.btn-i:hover {
  padding-right: 47px;
  margin-right: -11px;
  position: relative;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  &::after {
    content: "\f105";
    position: absolute;
    line-height: 0;
    top: 50%;
    right: 1.5rem;
    margin-top: 1px;
    font-family: $font-family_2;
  }
}
// forms
input[type="email"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
  &:focus {
    box-shadow: none;
    border: 1px solid #7c8859;
  }
}
input[type="password"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
  &:focus {
    box-shadow: none;
    border: 1px solid #7c8859;
  }
}
input[type="text"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
  &:focus {
    box-shadow: none;
    border: 1px solid #7c8859;
  }
}
input[type="tel"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
  &:focus {
    box-shadow: none;
    border: 1px solid #7c8859;
  }
}
.form-control {
  box-shadow: none;
  border-radius: 0;
  &:focus {
    box-shadow: none;
    border: 1px solid #7c8859;
  }
}

.sub-form {
  position: relative;
  .form-control {
    border: 1px solid rgba(0, 0, 0, 0.06);
    background: $background-color_3;
  }
}

//_news.scss
.nav-posts-title {
  line-height: 25px;
  font-size: 18px;
}
.news-pager .current {
  color: $color_4 !important;
  font-weight: 900 !important;
}
// .news-item {
// border-bottom: 1px solid rgba(0, 0, 0, 0.05);
// }
.post.post-single {
  border: none;
  .post-thumb {
    margin-top: 30px;
  }
}
.post-sub-heading {
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}
.post-social-share {
  margin-bottom: 50px;
}
.post-comments {
  margin: 30px 0;
  .media {
    margin-top: 20px;
    > .pull-left {
      padding-right: 20px;
    }
  }
  .comment-author {
    margin-top: 0;
    margin-bottom: 0px;
    font-weight: 500;
    a {
      color: $color_4;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  time {
    margin: 0 0 5px;
    display: inline-block;
    color: $color_14;
    font-size: 12px;
  }
  .comment-button {
    color: $color_4;
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    i {
      margin-right: 5px;
      display: inline-block;
    }
    &:hover {
      color: $color_4;
    }
  }
}
.post-excerpt {
  margin-bottom: 60px;
  h3 {
    a {
      color: $color_15;
    }
  }
  p {
    margin: 0 0 30px;
  }
  blockquote.quote-post {
    margin: 20px 0;
    p {
      line-height: 30px;
      font-size: 18px;
      // color: $color_16;
    }
  }
}
.single-news {
  background-color: $background-color_1;
  margin-bottom: 50px;
  padding: 20px;
}
.news-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
  a {
    color: $color_15;
    &:hover {
      color: $color_4;
    }
  }
  .prev-post {
    i {
      margin-right: 10px;
    }
  }
  .next-post {
    i {
      margin-left: 10px;
    }
  }
}
.comments-section {
  margin-top: 35px;
}
.author-about {
  margin-top: 40px;
}
.post-author {
  margin-right: 20px;
  > img {
    border: 1px solid #dedede;
    max-width: 120px;
    padding: 5px;
    width: 100%;
  }
}
.comment-list {
  ul {
    margin-top: 20px;
    li {
      margin-bottom: 20px;
    }
  }
}
.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
  .author-avatar {
    margin-right: 10px;
  }
  .media {
    .media-heading {
      font-size: 14px;
      margin-bottom: 8px;
      a {
        color: $color_4;
        font-size: 13px;
      }
    }
    .comment-meta {
      font-size: 12px;
      color: $color_17;
    }
    p {
      margin-top: 15px;
    }
  }
}
.comment-reply-form {
  margin-top: 80px;
  input {
    height: 35px;
    border-radius: 0;
    box-shadow: none;
    &:focus {
      box-shadow: none;
      border: 1px solid #7c8859;
    }
  }
  textarea {
    height: 35px;
    border-radius: 0;
    box-shadow: none;
    height: auto;
    &:focus {
      box-shadow: none;
      border: 1px solid #7c8859;
    }
  }
  .btn-main {
    height: auto;
  }
  .btn-transparent {
    height: auto;
  }
  .btn-small {
    height: auto;
  }
}

.progress {
  height: 32px;
  margin-bottom: 9px;
  text-shadow: #444 0 0 6px;
}

.news-img-wrap {
  max-height: 180px;
  overflow: hidden;
}
.news-img-large {
  max-height: 240px;
}
a.link-red {
  color: $color_6;
  &:hover {
    color: $color_3;
  }
}
.timeline {
  position: relative;
  padding: 10px;
  margin: 0 auto;
  overflow: hidden;
  color: $color_3;
  a {
    color: $color_3;
    font-weight: 600;
    text-decoration: underline;
    &:hover {
      color: $color_15;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    border-right: 2px dashed #c4d2e2;
    height: 100%;
    display: block;
  }
}
.timeline-row {
  padding-left: 50%;
  position: relative;
  margin-bottom: 30px;
  .timeline-time {
    position: absolute;
    right: 50%;
    top: 31px;
    text-align: right;
    margin-right: 20px;
    color: $color_15;
    font-size: 1.5rem;
    small {
      display: block;
      font-size: 0.9rem;
      color: $color_3;
    }
  }
  .timeline-content {
    position: relative;
    padding: 20px 30px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    &:after {
      content: "";
      position: absolute;
      top: 20px;
      height: 3px;
      width: 40px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 20px;
      right: -50px;
      width: 20px;
      height: 20px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      border-radius: 100px;
      z-index: 100;
      background: $color_3;
      border: 2px dashed #c4d2e2;
    }
    h4 {
      margin: 0 0 20px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 150%;
    }
    p {
      margin-bottom: 30px;
      line-height: 150%;
    }
    i {
      font-size: 2rem;
      color: $color_3;
      line-height: 100%;
      padding: 10px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      border-radius: 100px;
      margin-bottom: 10px;
      display: inline-block;
    }
    .thumbs {
      margin-bottom: 20px;
      img {
        margin-bottom: 10px;
      }
    }
  }
  &:nth-child(even) {
    .timeline-content {
      background-color: $color_8;
      margin-left: 40px;
      text-align: left;
      &:after {
        left: -39px;
        border-right: 18px solid $color_8;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
      &:before {
        left: -50px;
        right: initial;
      }
    }
  }
  &:nth-child(odd) {
    padding-left: 0;
    padding-right: 50%;
    .timeline-time {
      right: auto;
      left: 50%;
      text-align: left;
      margin-right: 0;
      margin-left: 20px;
    }
    .timeline-content {
      background-color: $color_12;
      margin-right: 40px;
      margin-left: 0;
      text-align: right;
      &:after {
        right: -39px;
        border-left: 18px solid $color_12;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
    }
  }
}

//_responsive.scss
@media (max-width: 992px) {
  #navbar {
    li {
      padding-left: 0;
    }
    .btn {
      margin: 15px 0 10px;
    }
  }
  .dropdown-menu {
    text-align: center;
    float: left !important;
    width: 100%;
    margin: 0;
  }
  .dropdown-item {
    padding: 0.6rem 1.5rem 0.35rem;
  }
  .slider {
    .block {
      h1 {
        font-size: 56px;
        line-height: 70px;
      }
    }
  }
  .bg-about {
    display: none;
  }
  section.about {
    border: 1px solid #dee2e6;
    border-left: 0;
    border-right: 0;
  }
  .footer-socials,
  .sidebar-socials {
    margin-top: 20px;
    li {
      a {
        margin-left: 0px;
      }
    }
  }
  .donors > div:nth-child(even) {
    background: $color_3 !important;
  }
  .donors > div:nth-child(odd) {
    background: $background-color_3 !important;
  }
}
@media (min-width: 992px) {
  .dropdown-menu {
    transition: all 0.5s ease-in, visibility 0s linear 0.5s, transform 0.5s linear;
    display: block;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    min-width: 200px;
    margin-top: 0;
    li {
      &:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
  .show > .dropdown-menu {
    max-height: 90vh;
    visibility: visible;
    // opacity: 1;
  }
  .dropleft {
    .dropdown-menu {
      margin-top: -10px;
    }
  }
  .dropright {
    .dropdown-menu {
      margin-top: -10px;
    }
  }
  .dropdown {
    &:hover {
      > .dropdown-menu {
        visibility: visible;
        transition: all 0.5s ease-in-out;
        opacity: 1;
      }
    }
  }
  .footer-menu li.nav-item {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 768px) {
  .slider {
    padding: 150px 0;
    .block {
      h1 {
        font-size: 48px;
        line-height: 62px;
      }
    }
  }
  h3.quote,
  .fancy-quote {
    padding: 0;
    padding-left: 20px;
    &::before {
      top: 5px;
      width: 2px;
      height: 35px;
    }
    .blockquote-footer {
      line-height: 1.5rem;
      margin-top: 1rem;
    }
  }
  .navbar-toggler {
    color: $color_3;
  }
  .bg-about {
    display: none;
  }
  .news-item-meta {
    span {
      margin: 6px 0px;
    }
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
  .timeline {
    padding: 15px 10px;
    &:after {
      left: 28px;
    }
    .timeline-row {
      padding-left: 0;
      margin-bottom: 16px;
      .timeline-time {
        position: relative;
        right: auto;
        top: 0;
        text-align: left;
        margin: 0 0 6px 56px;
        strong {
          display: inline-block;
          margin-right: 10px;
        }
      }
      .timeline-icon {
        top: 52px;
        left: -2px;
        margin-left: 0;
      }
      .timeline-content {
        padding: 15px;
        margin-left: 56px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        position: relative;
        &:after {
          right: auto;
          left: -39px;
          top: 32px;
        }
      }
      &:nth-child(odd) {
        padding-right: 0;
        .timeline-time {
          position: relative;
          right: auto;
          left: auto;
          top: 0;
          text-align: left;
          margin: 0 0 6px 56px;
        }
        .timeline-content {
          margin-right: 0;
          margin-left: 55px;
          &:after {
            right: auto;
            left: -39px;
            top: 32px;
            border-right: 18px solid $color_12;
            border-left: inherit;
          }
        }
      }
    }
  }
  .timeline.animated {
    .timeline-row {
      &:nth-child(odd) {
        .timeline-content {
          left: 20px;
        }
      }
    }
    .timeline-row.active {
      &:nth-child(odd) {
        .timeline-content {
          left: 0;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .scroll-to-top {
    bottom: 15px;
    right: 15px;
  }
  .header-top {
    .header-top-info {
      a {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .navbar-toggler {
    color: $color_3;
  }
  .slider {
    .block {
      h1 {
        font-size: 38px;
        line-height: 50px;
      }
    }
  }
  .content-title {
    font-size: 28px;
    line-height: 46px;
  }
  .p-5 {
    padding: 2rem !important;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 36px;
  }
  .h2 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 36px;
  }
  .testimonial-item {
    .testimonial-item-content {
      padding-left: 0px;
      padding-top: 30px;
    }
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
  //.header-top-notice {
  // display: none !important;
  //}
  .social-header,
  .header-buttons {
    display: block;
    padding-top: 3px;
  }
  .header-buttons {
    margin-top: 10px;
  }
  .footer-menu {
    .list-inline-item {
      display: block !important;
    }
  }
}
@media (max-width: 400px) {
  .header-top {
    .header-top-info {
      a {
        display: block;
      }
    }
  }
  .navbar-toggler {
    color: $color_3;
  }
  .content-title {
    font-size: 28px;
    line-height: 46px;
  }
  .bg-about {
    display: none;
  }
  .p-5 {
    padding: 2rem !important;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 36px;
  }
  .h2 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 36px;
  }
  .testimonial-item {
    .testimonial-item-content {
      padding-left: 0px;
      padding-top: 30px;
    }
  }
  .text-lg {
    font-size: 3rem;
  }
  .widget {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
}
.fr {
  #navbar {
    li {
      padding-left: 6px;
    }
    .nav-link {
      // font-size: 1rem;
      letter-spacing: -0.02em;
      text-transform: initial;
    }
  }
  .footer .navbar-nav {
    li {
      padding: 0 8px !important;
      a {
        font-size: 1rem !important;
        text-transform: initial;
      }
    }
    .dropdown-menu li {
      padding-inline: 0 !important;
    }
    .dropdown-item {
      font-size: 1rem !important;
    }
  }
}

.divider {
  .divider-top-inside {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmNWY4ZjkiPjxwYXRoIGQ9Ik0xMjgwIDE0MFYwSDBsMTI4MCAxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTEyODAgOThWMEgwbDEyODAgOTh6Ii8+PC9nPjwvc3ZnPg==);
    height: 100px;
    top: 0;
    z-index: 1;
    transform: scale(-1, 1);
  }
  .divider-bottom-inside {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIyNTBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmNWY4ZjkiPjxwYXRoIGQ9Ik0wIDB2MTQwaDEyODBMMCAweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0wIDQydjk4aDEyODBMMCA0MnoiLz48L2c+PC9zdmc+);
    background-size: 100% 120px;
    height: 120px;
    bottom: 0;
    z-index: 1;
    transform: scale(-1, 1);
  }
  &.divider-white {
    .divider-top-inside {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNGRkZGRkYiPjxwYXRoIGQ9Ik0xMjgwIDE0MFYwSDBsMTI4MCAxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTEyODAgOThWMEgwbDEyODAgOTh6Ii8+PC9nPjwvc3ZnPg==);
    }
    .divider-bottom-inside {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIyNTBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNGRkZGRkYiPjxwYXRoIGQ9Ik0wIDB2MTQwaDEyODBMMCAweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0wIDQydjk4aDEyODBMMCA0MnoiLz48L2c+PC9zdmc+);
    }
  }
  &-top.divider-flip {
    .divider-top-inside {
      transform: scale(1, 1);
    }
  }
  &-bottom.divider-flip {
    .divider-bottom-inside {
      transform: scale(1, 1);
    }
  }
  &-bottom-inside,
  &-top-inside {
    display: block;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    pointer-events: none;
    width: 100%;
    left: 0;
    right: 0;
  }
}
.datatable-bg {
  input[type="search"] {
    border-radius: 1rem;
  }
  table {
    border-radius: 0.5rem;
    border: 0 none;
  }
  label,
  .dataTables_info {
    color: $color_3;
  }
  th,
  td {
    border-top: 0 none;
    padding-left: 2rem;
  }
}
.accordion-striped > .card:nth-child(even) .card-header {
  background-color: #fefefe;
}
.page-title,
.slider {
  h1 {
    font-family: "Oswald", sans-serif !important;
    letter-spacing:1px;
  }
}
.gw {
  display: inline-block;
  padding-inline: 4px;
  a {
    color:#d2d2d2;
    font-weight:normal !important;
  }
}